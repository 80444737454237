import banner from './images/banner.png';
import fireGif from './images/flames.gif';
import styled from 'styled-components';
import cuteDog from './images/dog.png';
import { keyframes } from 'styled-components';

function App() {
  return (
    <div className="App">
        <AppContainer>
            <BannerContainer>
                <Banner src={banner} alt="banner" />
                <TextOverlay>DOGWIFSUIT</TextOverlay>
            </BannerContainer>
            <LinksContainer>
                <Link href="https://t.me/dogwifsuit_sol">Telegram</Link>
                <Link href="https://www.youtube.com/watch?v=zhbAMCmYCeY">Chart</Link>
                <Link href="https://twitter.com/sol_dogwifsuit">Twitter</Link>
            </LinksContainer>
            <CuteDogContainer>
                <CuteDog src={cuteDog} alt="cute dog" />
            </CuteDogContainer>
            <FireGifContainer>
                <FireGif src={fireGif} alt="fire" />
                <FireGif src={fireGif} alt="fire" />
                <FireGif src={fireGif} alt="fire" />
                <FireGif src={fireGif} alt="fire" />
                <FireGif src={fireGif} alt="fire" />
                <FireGif src={fireGif} alt="fire" />
                <FireGif src={fireGif} alt="fire" />
                <FireGif src={fireGif} alt="fire" />
            </FireGifContainer>
        </AppContainer>
    </div>
  );
}

const AppContainer = styled.div`
    text-align: center;
    background-color: #000000;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const BannerContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Banner = styled.img`
    width: 100%;
    position: relative;
`;

const TextOverlay = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    font-size: 3em;
    font-weight: bold;
`;

const LinksContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 1;
`;

const Link = styled.a`
    color: white;
    font-size: 2em;
    margin: 0 1em;
`;

const FireGifContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 10%;
    display: flex;
`;

const FireGif = styled.img`
    flex: 1;
    object-fit: cover;
    filter: hue-rotate(270deg);
`;

const dance = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(120px); }
  50% { transform: translateX(0); }
  75% { transform: translateX(-120px); }
  100% { transform: translateX(0); }
`;

const CuteDogContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CuteDog = styled.img`
    width: 10%;
    height: 10%;
    animation: ${dance} 4s linear infinite;
    margin-top: 25%;
`;

export default App;
